<template>
  <div>
    <home-navbar></home-navbar>
    <div class="bl_content">
      <div class="bl_search">
        <input
          class="form-control shadow-1 search"
          type="text"
          v-model="searchWord"
          placeholder="キーワード検索"
          @change="search()"
        />
        <div class="search_prefecture">
          <b-form-select
            class="shadow-1 search"
            v-model.number="prefecture_id"
            @change="search()"
          >
            <b-form-select-option value=0>都道府県</b-form-select-option>
            <b-form-select-option :value="prefecture.id" v-for="(prefecture, index) in prefectures" :key="index">{{ prefecture.name }}</b-form-select-option>
          </b-form-select>
          <div class="gps" @click="getCurrentPrefecture()">
            <img class="icon_gps" src="../../public/img/icons/ico-notification-bell.svg" alt="GPS">
          </div>
        </div>
      </div>
      <div class="bl_image_list">
        <div class="bl_image" v-for="(item,index) in displayList" :key="index" @click="toDetail(item.id)">
          <img class="image" :src="item.result_file_url" alt="image">
          <div class="image_title">{{ item.title }}</div>
        </div>
      </div>
    </div>
    <home-footer></home-footer>
  </div>
</template>
<script src="https://unpkg.com/beerslider/dist/BeerSlider.js"></script>
<script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyCsWywCbXFr1Kju-h-XEc_46MndAqpBB9I"></script>
<script>
import homeNavbar from '../components/customer/Home-Navbar.vue';
import homeFooter from '../components/customer/Home-Footer.vue';
import { globalService_GetDesignList, globalService_GetAllPrefectures } from '../services/global';

export default {
  metaInfo() {
    return {
      title: 'Digital Staging - Design',
    };
  },
  components: {
    'home-navbar': homeNavbar,
    'home-footer': homeFooter,
  },
  data() {
    return {
      searchWord: null,
      prefectures: [],
      imageList: [],
      displayList: [],
      prefecture_id: 0,
    };
  },
  created(){
    this.getImageList();
    this.getPrefectures();
  },
  methods: {
    getImageList(){
      globalService_GetDesignList().then((response) => {
        this.imageList = response.data.result;
        this.displayList = this.imageList
      })
      .catch((error) => {
        console.log(error);
      });
    },
    getPrefectures(){
      globalService_GetAllPrefectures().then((response) => {
        this.prefectures = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
    },
    getCurrentPrefecture(){
      if( navigator.geolocation ){
        this.$store.state.modalLoaderMessage = '現在地取得中';
        this.$bvModal.show('modal-loader');
        navigator.geolocation.getCurrentPosition(res=>{
          const geocoder = new google.maps.Geocoder();
          const latlng = new google.maps.LatLng(res.coords.latitude, res.coords.longitude);
          geocoder.geocode({'latLng': latlng}, (result, status) => {
            if (status == google.maps.GeocoderStatus.OK) {
              const prefectureResult = result[0].address_components.filter(res=>{
                return res.types.indexOf("administrative_area_level_1") > -1;
              });
              const prefecture = prefectureResult[0].long_name;
              this.prefectures.forEach((value) => {
                if (value.name === prefecture) {
                  this.prefecture_id = value.id;
                }
              });
              this.search();
              this.$bvModal.hide('modal-loader');
            } else {
              console.log('errror'+status);
            }
          });
        }, error => {
          console.log(error);
        });
      } else {
        console.log('このブラウザ位置情報は取得できません。');
      }
    },
    search(){
      if (this.searchWord) {
        this.wordSearch(this.imageList);
        if (this.prefecture_id !== 0) {
          this.prefectureSearch(this.displayList);
        }
      } else {
        if (this.prefecture_id !== 0) {
          this.prefectureSearch(this.imageList);
        } else {
          this.displayList = this.imageList;
        }
      }
    },
    wordSearch(list){
      this.displayList = list.filter((value)=>{
        let isTitle = false
        let isText = false
        let isTag = false
        if (value.title && value.title.indexOf(this.searchWord) !== -1) {
          isTitle = true
        }
        if (value.text && value.text.indexOf(this.searchWord) !== -1) {
          isText = true
        }
        if (value.tag && value.tag.indexOf(this.searchWord) !== -1) {
          isTag = true
        }
        return isTitle || isText || isTag
      })
    },
    prefectureSearch(list){
      this.displayList = list.filter((value)=>{
        return this.prefecture_id === value.prefecture_id
      });
    },
    categorySearch(){
      //カテゴリ絞り込み処理
    },
    toDetail(id){
      this.$router.push({
        name: 'anonymousRoute-design-detail',
        params: {
          id: id,
        }
      });
    }
  }
}
</script>

<style scoped>
  .bl_content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 74px;
  }

  .bl_content .bl_search{
    display: flex;
    padding: 20px 0;
  }

  .bl_content .bl_search .search_prefecture{
    display: flex;
    align-items: center;
  }

  .bl_content .bl_search .search,
  .bl_content .bl_search .search_prefecture .search{
    width: 200px;
    margin-right: 20px;
  }

  @media only screen and (max-width: 499px) {
    .bl_content .bl_search {
      flex-direction: column;
      align-items: center;
    }

    .bl_content .bl_search .search_prefecture{
      margin-top: 20px;
    }

    .bl_content .bl_search .search{
      width: 100%;
      margin-right: 0;
    }

    .bl_content .bl_search .search_prefecture .search{
      width: 200px;
      margin-right: 20px;
    }
  }

  .bl_content .bl_search .gps{
    height: 40px;
  }

  .bl_content .bl_search .gps:hover{
    cursor: pointer;
  }

  .bl_content .bl_search .gps .icon_gps{
    height: 100%;
  }

  .bl_content .bl_image_list{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: calc(6 * 180px);
    margin: 0 auto 50px;
  }

  @media only screen and (max-width: 1099px) {
    .bl_content .bl_image_list{
      width: calc(5 * 180px);
    }
  }

  @media only screen and (max-width: 919px) {
    .bl_content .bl_image_list{
      width: calc(4 * 180px);
    }
  }

  @media only screen and (max-width: 739px) {
    .bl_content .bl_image_list{
      width: calc(3 * 180px);
    }
  }

  @media only screen and (max-width: 559px) {
    .bl_content .bl_image_list{
      width: calc(2 * 180px);
    }
  }

  @media only screen and (max-width: 379px) {
    .bl_content .bl_image_list{
      width: calc(1 * 365px);
    }
  }

  .bl_content .bl_image_list .bl_image{
    display: flex;
    flex-wrap: wrap;
    width: 170px;
    margin: 0 5px;
  }

  .bl_content .bl_image_list .bl_image:hover{
    cursor: pointer;
  }

  .bl_content .bl_image_list .bl_search_category{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 170px;
    margin: 0 5px;
  }

  .bl_content .bl_image_list .bl_search_category .search_label{
    font-weight: bold;
    padding: 5px 0 10px;
  }

  .bl_content .bl_image_list .bl_search_category .bl_category{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
  }

  .bl_content .bl_image_list .bl_search_category .bl_category .category_left{
    width: calc(calc(100% - 6px) / 2);
    margin-right: 3px;
  }

  .bl_content .bl_image_list .bl_search_category .bl_category .category_right{
    width: calc(calc(100% - 6px) / 2);
    margin-left: 3px;
  }

  .bl_content .bl_image_list .bl_search_category .bl_category .category_left:hover,
  .bl_content .bl_image_list .bl_search_category .bl_category .category_right:hover{
    cursor: pointer;
  }

  .bl_content .bl_image_list .bl_search_category .bl_category .category_left .image,
  .bl_content .bl_image_list .bl_search_category .bl_category .category_right .image{
    width: 100%;
    object-fit: cover;
    border-radius: 45px;
  }

  .bl_content .bl_image_list .bl_search_category .bl_category .category_left .category_text,
  .bl_content .bl_image_list .bl_search_category .bl_category .category_right .category_text{
    text-align: center;
    width: 100%;
  }

  .bl_content .bl_image_list .bl_image .image{
    height: 200px;
    width:170px;
    object-fit: cover;
    border-radius: 15px;
  }

  .bl_content .bl_image_list .bl_image .image_title{
    padding: 5px 5px 20px;
    font-weight: bold;
  }
</style>